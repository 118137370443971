












































































































































































































































import Vue from 'vue'
import { PropType } from 'vue'
import { DataOptions, DataPagination } from 'vuetify'
import { IFilter, IDataTableHeader, IActions } from '@/types/table'
import FormFilter from '@/components/dataTable/components/FormFilter.vue'
import { formatDate, hasOwnProperty, formatCurrencyWithSpaces } from '@/utils/helpers'
import { isEmpty } from 'lodash'
import { dateColorSwatch } from '@/utils/colorSwatches'
import { IObject } from '@/types/helpers'
import { IChoice } from '@/types/select'

//TODO bug with invalid query param
//TODO fix loading by adding new custom slot for loading
export default Vue.extend({
	name: 'DataTable',
	components: {
		FormFilter,
	},
	props: {
		headers: {
			type: Array as PropType<Array<IDataTableHeader>>,
			required: true,
		},
		items: {
			type: Array as PropType<Array<IObject<any>>>,
			default: () => [],
		},
		itemsPerPage: {
			type: Number,
			default: () => 50,
		},
		itemsPerPageData: {
			type: Array as PropType<Array<IChoice>>,
			default: () => [
				{
					value: 5,
					text: '5',
				},
				{
					value: 15,
					text: '15',
				},
				{
					value: 30,
					text: '30',
				},
				{
					value: 50,
					text: '50',
				},
				{
					value: 100,
					text: '100',
				},
				{
					value: -1,
					text: 'All',
				},
			],
		},
		sort: {
			type: Boolean,
			default: () => false,
		},
		sortBy: {
			type: Array as PropType<Array<string>>,
			default: () => [],
		},
		sortDesc: {
			type: Boolean,
			default: () => false,
		},
		loading: {
			type: Boolean,
			default: () => false,
		},
		filters: {
			type: Array as PropType<Array<IFilter>>,
			default: () => [],
		},
		iterator: {
			type: Boolean,
			default: () => false,
		},
		actions: {
			type: Array as PropType<Array<IActions>>,
			default: () => undefined,
		},
		addButton: {
			type: Boolean,
			default: () => false,
		},
		height: {
			type: String,
			default: () => '100%',
		},
		disablePagination: {
			type: Boolean,
			default: () => false,
		},
		serverItemsLength: {
			type: Number,
		},
		dateTimeFormat: {
			type: String,
			default: () => null,
		},
		sum: {
			type: Object as PropType<IObject<any>>,
		},
	},
	data() {
		return {
			pagination: {} as DataPagination,
			enabledFilters: [] as Array<string>,
			currentFilter: {} as IObject<any>,
			listState: {
				options: {} as DataOptions,
				reload: (): void => {
					//it works, but I don't know how to properly fix type error
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					this.updateQuery()
				},
			},
		}
	},
	computed: {
		pageCountList(): Array<number> {
			return Array(this.$data.pagination.pageCount)
				.fill(undefined, undefined, undefined)
				.map((x, i) => ++i)
		},
		getFilters(): IFilter[] {
			return this.filters.map((f) => {
				return {
					...f,
					type: f.type || 'TextInput',
					label: f.label || f.source,
				}
			})
		},
		getEnabledFilters(): IFilter[] {
			return this.getFilters.filter((f) => {
				return f.alwaysOn || this.enabledFilters.includes(f.source)
			})
		},
		getDisabledFilters(): IFilter[] {
			return this.getFilters.filter((f) => {
				return !f.alwaysOn && !this.enabledFilters.includes(f.source)
			})
		},
		headersCount(): number {
			let headers = this.headers.length

			if (this.iterator) {
				headers += 1
			}

			if (this.actions) {
				headers += 1
			}

			return headers
		},
	},
	watch: {
		currentFilter() {
			this.updateQuery()
		},
		'$route.query'() {
			this.fetchData()
		},
	},
	methods: {
		/**
		 * function for paring nested headers
		 *
		 * @param id
		 * @param obj
		 */
		parseItemsData(id: string, obj: unknown): any {
			if (!!obj && typeof obj === 'object' && id) {
				let newObj
				if (id.includes('.')) {
					let newIdSplit = id.split('.')
					let newId = newIdSplit.splice(0, 1)[0]
					newObj = hasOwnProperty(obj, newId) ? obj[newId] : ''
					newObj = newObj ? this.parseItemsData(newIdSplit.join('.'), newObj) : ''
				} else {
					newObj = hasOwnProperty(obj, id) ? obj[id] : ''
				}
				//TODO need check but this logic ignore 0 symbol
				//return newObj ? newObj : ''
				return newObj
			} else {
				return ''
			}
		},
		formatDate: formatDate,
		dateColorSwatch: dateColorSwatch,
		formatCurrency: formatCurrencyWithSpaces,
		/**
		 * custom hading of pagination
		 * @param pagination
		 */
		getPaginationData(pagination: DataPagination): void {
			if (pagination.pageCount <= 1) {
				pagination.page = 1
				this.listState.options.page = 1
			}

			this.pagination = pagination
		},
		//TODO this is work in progress probably need to look for better solution
		columnSort(value: IDataTableHeader) {
			if (value.sortable) {
				console.log(value)
				this.listState.options.sortBy.push(value.value)
				console.log(this.listState.options.sortBy)
			}
		},
		/**
		 * inits query parameters from url
		 */
		initQuery() {
			let options = {
				page: 1,
				itemsPerPage: !this.disablePagination ? this.itemsPerPage : -1,
			} as DataOptions

			const { itemsPerPage, page, filter } = this.$route.query

			if (itemsPerPage && !Array.isArray(itemsPerPage)) {
				options.itemsPerPage = parseInt(itemsPerPage, 10)
			}

			if (page && !Array.isArray(page)) {
				options.page = parseInt(page, 10)
			}

			this.listState.options = options

			if (filter && !Array.isArray(filter)) {
				this.currentFilter = JSON.parse(filter)

				for (let val in this.currentFilter) {
					let filter = this.getFilters.find((f) => f.source === val)

					if (filter && this.enabledFilters.indexOf(filter.source)) {
						this.enableFilter(filter)
					}
				}
			} else {
				for (let val in this.currentFilter) {
					let filter = this.getFilters.find((f) => f.source === val)

					if (filter && !filter.defaultValue) {
						this.disableFilter(filter)
						Object.keys(this.currentFilter).forEach((key) => delete this.currentFilter[key])
					}
				}
			}
		},
		/**
		 * updates query parameters when some filter or pagination, etc. changes
		 */
		updateQuery(): void {
			const { itemsPerPage, page } = this.listState.options

			let query = {
				...(!this.disablePagination && { page: page.toString(10) }),
				...(!this.disablePagination && { itemsPerPage: itemsPerPage.toString(10) }),
				...(!isEmpty(this.currentFilter) && { filter: '' }),
			}

			if (!isEmpty(this.currentFilter)) {
				query.filter = JSON.stringify(this.currentFilter)
			}

			if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
				this.$router.push({ query: query })
			}
		},
		/**
		 * emits event fetchData when data table should fetch data from api
		 */
		fetchData() {
			const { page, itemsPerPage } = this.listState.options

			let params = {
				pagination: {} as Partial<DataPagination>,
				filters: this.currentFilter,
			}

			if (!this.disablePagination) {
				params.pagination = {
					page,
					itemsPerPage,
				}
			}

			this.$emit('fetchData', params)
		},
		/**
		 * function for enabling filters
		 *
		 * @param filter
		 */
		enableFilter(filter: IFilter): void {
			this.enabledFilters.push(filter.source)
		},
		/**
		 * function for disabling filters
		 *
		 * @param filter
		 */
		disableFilter(filter: IFilter): void {
			this.enabledFilters.splice(this.enabledFilters.indexOf(filter.source), 1)
		},
		/**
		 * sets default value to object currentFilter when provided
		 */
		setDefaultValueFilter() {
			if (this.filters) {
				this.filters.forEach((f) => {
					if (f.defaultValue && f.alwaysOn) {
						this.currentFilter[f.source] = f.defaultValue
					}
				})
			}
		},
		/**
		 * helper function for checking if value (data) is array in template
		 *
		 * @param data
		 */
		isArray(data: never): boolean {
			return Array.isArray(data)
		},
		/**
		 * function for emitting action from table like add button or row actions
		 *
		 * @param name
		 * @param item
		 */
		actionEmit(name: string, item: never): void {
			this.$emit(name, item)
		},
	},
	created() {
		this.setDefaultValueFilter()
		this.initQuery()
	},
	mounted() {
		this.fetchData()
	},
})
